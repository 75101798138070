import logo from "@/assets/logo_big.png";
import logoGatti from "@/assets/logo_gatti.png";
import logoGattiWhite from "@/assets/logo_big_white.png";
import borders from "@/theme/base/borders";
import Grid from "@mui/material/Grid";
import { ReactNode } from "react";
import Box from "../Box";
import Footer from "../Footer";
import Typography from "../Typography";
import colors from "@/theme/base/colors";
import welcomeToTheFuture from "@/assets/login/welcome_to_the_future.png";
import logoLinkedin from "@/assets/login/linkedin.png";
import logoFacebook from "@/assets/login/facebook.png";
import logoInstagram from "@/assets/login/instagram.png";
import logoYoutube from "@/assets/login/youtube.png";

type CoverLayoutProps = {
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "light";
  header?: ReactNode;
  title?: string;
  description?: string;
  image?: string;
  top?: number;
  children?: ReactNode;
  showLogo?: boolean;
};

function CoverLayout(props: CoverLayoutProps) {
  const {
    color = "info",
    header = "",
    title = "",
    description = "",
    image,
    top = props.showLogo ? 10 : 20,
    children,
    showLogo = false,
  } = props;

  return (
    <Box
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor="white"
      sx={{ overflowX: "hidden", display: "flex", flexDirection: "column" }}
    >
      <Box
        width="100vw"
        bgColor="white"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          p: 4,
          alignItems: "center",
        }}
      >
        <img
          src="/onde.svg"
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            minWidth: 500,
            width: "50vw",
          }}
        />
        <Box>
          <img src={logoGatti} height="64px" />
        </Box>

        <Box
          sx={{
            flexDirection: "row",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={welcomeToTheFuture} height="48px" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              m: 2,
              gap: 2,
              alignItems: "center",
            }}
          >
            <a
              href="https://www.linkedin.com/company/gatti-filtrazioni-lubrificanti/"
              target="_blank"
            >
              <img src={logoLinkedin} width="32" height="32" />
            </a>
            <a
              href="https://www.facebook.com/Gattifiltrazionilubrificanti/"
              target="_blank"
            >
              <img src={logoFacebook} width="32" height="32" />
            </a>
            <a
              href="https://www.instagram.com/gattifiltrazionilubrificanti/"
              target="_blank"
            >
              <img src={logoInstagram} width="32" height="32" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCx6aUZ_JiM-EK2e_mQ8-vTg/videos"
              target="_blank"
            >
              <img src={logoYoutube} width="32" height="32" />
            </a>
          </Box>
        </Box>
      </Box>

      <Box
        width="100vw"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "hidden",
          flex: 1,
          backgroundColor: colors.primary.main,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 8,
            p: 1,
            minWidth: "280px",
            maxWidth: "400px",
            width: "100%",
          }}
        >
          <img
            src={logoGattiWhite}
            width="100%"
            alt="Gatti Filtrazioni Lubrificanti"
          />

          <Box
            pt={8}
            sx={{ display: "flex", flexDirection: "column", flex: 1 }}
          >
            {!header ? (
              <Typography
                variant="h4"
                fontWeight="regular"
                sx={{ color: colors.secondary.main, textAlign: "center" }}
              >
                {description}
              </Typography>
            ) : (
              header
            )}
          </Box>
          <Box pt={3} sx={{ minWidth: 300 }}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor="white"
      sx={{ overflowX: "hidden" }}
    >
      <Grid
        container
        justifyContent="center"
        sx={{
          minHeight: "75vh",
          margin: 0,
        }}
      >
        <Grid item xs={11} sm={11} md={8} lg={5} xl={3}>
          <Box mt={top}>
            {showLogo && (
              <img
                src={logo}
                width="100%"
                alt="Gatti Filtrazioni Lubrificanti"
              />
            )}
            <Box pt={8} px={3}>
              {!header ? (
                <>
                  <Box mb={1}>
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      color={color}
                      textGradient
                    >
                      {title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" fontWeight="regular" color="text">
                    {description}
                  </Typography>
                </>
              ) : (
                header
              )}
            </Box>
            <Box p={3}>{children}</Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={4} xl={7}>
          <Box
            height="100%"
            display={{ xs: "none", md: "none", lg: "block" }}
            position="relative"
            right={{ lg: "-12rem", xl: "-16rem" }}
            mr={-16}
            sx={{
              transform: "skewX(-10deg)",
              overflow: "hidden",
              borderBottomLeftRadius: borders.borderRadius.lg,
            }}
          >
            <Box
              ml={-12}
              height="100%"
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                transform: "skewX(10deg)",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
}

export default CoverLayout;
