import { AnalisiDto, CentraleDto, getDownloadPdfAnalisiQueryKey } from "@/api";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Table from "@/elements/Table";
import moment from "moment/moment";
import { createColumnHelper } from "@tanstack/react-table";
import { ReactNode, useMemo } from "react";
import FileDownload from "@/components/FileField/FileDownload";
import WarningIcon from "@/assets/warning.png";
import ErrorIcon from "@/assets/error.png";
import { ScadenzaAnalisiWarningDays } from "@/utils/scadenzeUtils";
import { Tooltip } from "@mui/material";

const columnHelper = createColumnHelper<AnalisiDto>();

export default function AnalisiTableCard({
  centrale,
  analisi,
}: {
  centrale: CentraleDto;
  analisi: AnalisiDto[];
}) {
  const columns = useMemo(
    () =>
      [
        columnHelper.accessor("data", {
          header: "Data",
          cell: ({ row }) => moment(row.original.data).format("L"),
        }),

        columnHelper.accessor("esito", {
          header: "Esito",
          cell: ({ getValue }) => <PallinoAnalisi esito={getValue()} />,
        }),

        columnHelper.accessor("validaFinoA", {
          header: "Valida fino a",
          cell: ({ row }) => {
            let icon: ReactNode | null = null;
            if (
              centrale.ultimaAnalisi?.id === row.original.id &&
              row.original.validaFinoA
            ) {
              const days = moment(row.original.validaFinoA).diff(
                moment(),
                "days"
              );
              icon =
                days <= 0 ? (
                  <img src={ErrorIcon} width={20} alt="Scaduta" />
                ) : days <= ScadenzaAnalisiWarningDays ? (
                  <img src={WarningIcon} width={20} alt="In scadenza" />
                ) : null;
            }

            return (
              <Box display="flex" gap={1} alignItems="center">
                {moment(row.original.validaFinoA).format("L")}
                {icon}
              </Box>
            );
          },
        }),

        columnHelper.display({
          header: "PDF",
          cell: ({ row }) => (
            <FileDownload
              file={row.original.filePdf}
              downloadUrl={
                getDownloadPdfAnalisiQueryKey(centrale.id, row.original.id)[0]
              }
            />
          ),
        }),
      ].filter((x) => x),
    [centrale.id]
  );

  return (
    <Box p={2} flex={1}>
      <Box display="flex" flexDirection="column" height="100%" gap={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={1}
        >
          <Typography variant="h5" color="text" fontWeight="medium">
            Storico analisi
          </Typography>
        </Box>

        <Table
          columns={columns}
          data={analisi}
          initialSort={[{ id: "data", desc: true }]}
          noRowsText="Nessuna analisi disponibile"
          mt={1}
        />
      </Box>
    </Box>
  );
}

function PallinoAnalisi({ esito }: { esito: string | null | undefined }) {
  const color =
    esito === "VERDE"
      ? "#90ee90"
      : esito === "GIALLO"
      ? "#ffff00"
      : esito === "ROSSO"
      ? "#ff0000"
      : null;

  if (!color) {
    return <Typography>{esito}</Typography>;
  }

  return (
    <Tooltip title={esito}>
      <div
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: color,
          borderRadius: "50%",
        }}
      />
    </Tooltip>
  );
}
